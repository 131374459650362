<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="" style="display: inline-block;">
      <span class="fl mg-rt-10">
        <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
      </span>
      <span class="fl">
      <el-upload
          class="upload-box"
          ref="upload"
          :show-file-list="false"
          :before-upload="beforeUpload"
          accept=".mp4"
          action="#">
        <el-button size="small" type="primary">上传视频</el-button>
        <div slot="tip" class="el-upload__tip">只能上传mp4文件，且不超过100M</div>
      </el-upload>
        <el-progress :percentage="percent" style="width: 300px; margin-top: 5px" v-if="percent>=0"></el-progress>
      </span>
    </div>
    <div style="clear: both; margin-top: 20px"></div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%;"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题">
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="图片"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="60" :src="scope.row.img"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="size"
          width="100"
          align="center"
          label="大小">
        <template slot-scope="scope">
          {{ scope.row.size }}M
        </template>
      </el-table-column>
      <el-table-column
          prop="second"
          width="100"
          align="center"
          label="长度">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog title="修改标题" :visible.sync="dialogVisible" custom-class="dialog" :close-on-click-modal="false">
      <el-form size="small">
        <el-form-item label="标题" label-width="120px">
          <el-input v-model="v_title" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveTitle()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import * as qiniu from 'qiniu-js'
export default {
  components: {},
  data() {
    return {
      info: false,
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      qiniuToken: '',
      percent: -1,
      v_title: '',
      v_id: 0
    };
  },
  created() {
    this.getToken()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    saveTitle() {
      this.$api.course.courseVideoEdit({id:this.v_id,title:this.v_title},res => {
        if(res.errcode==0) {
          this.dialogVisible = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getToken() {
      this.$api.merchant.qiniuToken({},res => {
        if(res.errcode==0) {
          this.qiniuToken = res.data
        }
      })
    },
    beforeUpload(file){
      if ( file.name.split('.')[1].toLowerCase() != 'mp4'){
        this.fail('文件格式错误，只允许后缀名为.mp4的文件')
        return false;
      }
      if(file.size>1024*1024*1024) {
        this.fail('文件不能大于1000M')
        return false;
      }
      this.uploadVideo(file);
    },
    // 上传视频至七牛云
    uploadVideo(file){
      const suffix = file.name.split('.')[1];
      const _this = this;
      const nowDate = new Date()
      const current = nowDate.getTime();
      const day = nowDate.getFullYear()+''+(nowDate.getMonth()+1)+''+nowDate.getDate()
      const random = parseInt(Math.random()*100000)
      const key = `video/${day}/${current}_${random}.${suffix}`;
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z2,
        concurrentRequestLimit: 1000,
      };
      var observable = qiniu.upload(file, key, this.qiniuToken, config);
      var observer = {
        next(res) {
          _this.percent = parseInt(res.total.percent);
        },
        error(err) {
          _this.percent = -1
          console.log(err)
          _this.fail(err.message);
        },
        complete(res) {
          _this.percent = -1
          _this.success('上传成功！');
          _this.getList()
        },
      };
      this.subscription = observable.subscribe(observer);
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.course.courseVideoIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editGroup(item) {
      this.v_title = item.title
      this.v_id = item.id
      this.dialogVisible = true
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.course.courseVideoDelete({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
.upload-box {
  display: inline-block;
}
</style>
